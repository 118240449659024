<template>
    <slot name="toggle"></slot>
    <slot name="content"></slot>
</template>

<script>
import {MenuComponent} from "@/assets/js/components";

export default {
    name: "kt-menu-component",
    components: {},
    props: {
        menuSelector: {type: String},
    },
    mounted() {
        this.$nextTick(() => {
            MenuComponent.createInsance(this.menuSelector);
        });
    }
}
</script>
